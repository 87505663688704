import { Box, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { DataGridPro, GridCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { useQualityCheckData } from "../../hooks/useQualityCheck";
import ImageViewerDialog from "./DialogImgViewer";
import PreviewIcon from "@mui/icons-material/Preview";
const QualityCheck = () => {
  const [selectedDate, setSelectedDate] = React.useState<string | undefined>(undefined);
  const { error, loading, data } = useQualityCheckData(selectedDate);
  const [imageViewerDialogState, setImageViewerDialogState] = React.useState({ open: false, row: null });
  const openImageViewer = (params: GridCellParams) => {
    console.log(params);
    if (params.row.fotos?.length <= 0) return alert("No Pictures to view and downlaod.");
    setImageViewerDialogState((prev) => ({ open: true, row: params.row }));
  };
  const columns: GridColumns = getGridColumns();
  return (
    <>
      {imageViewerDialogState?.open && <ImageViewerDialog imageViewerDialogState={imageViewerDialogState} setImageViewerDialogState={setImageViewerDialogState} />}
      <Box sx={{ width: "100%", height: "100%", py: 1 }}>
        <Paper sx={{ height: "100%" }}>
          <Typography variant="h5"> Quality check</Typography>
          <Box sx={{ ml: 2, mt: 2, display: "flex", alignItems: "flex-end" }}>
            <Typography variant="body1">Select a date</Typography>
            <TextField sx={{ ml: 2 }} variant="standard" type={"date"} value={selectedDate} onChange={(e: any) => setSelectedDate(e.target.value)} />
          </Box>

          <DataGridPro onCellDoubleClick={(params) => openImageViewer(params)} loading={loading} rows={data} columns={columns} />
        </Paper>
      </Box>
    </>
  );
};

export default QualityCheck;

function getGridColumns(): GridColumns {
  return [
    { field: "pro_co", headerName: "Proco", minWidth: 100, flex: 1 },
    { field: "pro_location", headerName: "Production Location", minWidth: 100, flex: 1 },
    { field: "program", headerName: "Program", minWidth: 100, flex: 1 },
    { field: "article", headerName: "Article", minWidth: 100, flex: 1 },
    { field: "date", headerName: "Date", minWidth: 100, flex: 1 },
    { field: "timeslot", headerName: "Timeslot", minWidth: 100, flex: 1 },
    { field: "comment", headerName: "Comment", minWidth: 100, flex: 1 },
    { field: "label_ic", headerName: "Label IC", minWidth: 100, flex: 1 },
    { field: "label_oc", headerName: "Label OC", minWidth: 100, flex: 1 },
    { field: "um", headerName: "UM", minWidth: 100, flex: 1 },
    { field: "polybag", headerName: "Polybag", minWidth: 100, flex: 1 },
    { field: "protector", headerName: "Protector", minWidth: 100, flex: 1 },
    { field: "silk_paper", headerName: "Silk paper", minWidth: 100, flex: 1 },
    { field: "closing_label", headerName: "Closing label", minWidth: 100, flex: 1 },
    { field: "ean_code", headerName: "EAN-code", minWidth: 100, flex: 1 },
    { field: "delabel_ic", headerName: "Delabel IC", minWidth: 100, flex: 1 },
    { field: "delabel_oc", headerName: "Delabel OC", minWidth: 100, flex: 1 },
    { field: "icp_sleeve", headerName: "ICP/Sleeve", minWidth: 100, flex: 1 },
    { field: "ocp_ocb", headerName: "OCP/OCB", minWidth: 100, flex: 1 },
    { field: "tag", headerName: "Tag", minWidth: 100, flex: 1 },
    {
      field: "fotos",
      headerName: "Fotos",
      minWidth: 100,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row.fotos?.length}
            <PreviewIcon />
          </>
        );
      },
    },
    { field: "silk_paper", headerName: "Silk paper", minWidth: 100, flex: 1 },
  ];
}
