import { Chip } from "@mui/material";

const initState = {
  locationTableData: {},
  chips: [] as any,
  selectedContent: 0,
  transactiesFilter: "",
  deviceData: [] as any,
  updater: true,
  customerList: [] as any,
  locationList: [] as any,
  devices: [] as any,
  locations: [] as any,
  transactions: [] as any,
  customerSettings: {} as any,

  workflows: [] as any,
  editingWorkfow: {} as any,
  workflowPage: 0,
  usersList: [] as any,
  userToEdit: {} as any,
  ordersList: [] as any,
  orderEventTransactions: [] as any,
  groupsList: {} as any,
  groupToEdit: {} as any,
  reportConfirmed: false as boolean,
  clientDeviceLocations: [] as any,
  userFilterItems: [] as any,
};

const dataReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "SUBSCRIBE_TO_DATA":
      return {
        ...state,
      };
    case "UPDATE_CHIPS":
      return {
        ...state,
        chips: action.chips,
      };
    case "ADD_CHIP":
      let tempChip = [];
      if (!state.chips) {
        tempChip.push(action.chip);
      } else {
        tempChip = [...state.chips, action.chip];
      }

      return {
        ...state,
        chips: tempChip,
      };
    case "DELETE_CHIP":
      let tempChips = state.chips.filter((chip: any) => chip.id !== action.id);
      return {
        ...state,
        chips: tempChips,
      };
    case "UPDATE_SELECTED_CONTENT":
      return {
        ...state,
        selectedContent: action.selectedContent,
      };
    case "UPDATE_TRANSACTIES_FILTER":
      return {
        ...state,
        transactiesFilter: action.filterString,
      };
    case "UPDATE_DEVICE_DATA":
      return {
        ...state,
        devices: action.deviceData,
      };
    case "UPDATE_UPDATER":
      return {
        ...state,
        updateCounter: action.update,
      };
    case "UPDATE_CUSTOMERLIST":
      return {
        ...state,
        customerList: action.customers,
      };
    case "UPDATE_LOCATIONS":
      return {
        ...state,
        locations: action.locations,
      };
    case "UPDATE_TRANSACTIONSLIST":
      return {
        ...state,
        transactions: action.transactions,
      };
    case "UPDATE_LOCATIONSLIST":
      return {
        ...state,
        locationList: action.locationsData,
      };
    case "UPDATE_CUSTOMER_SETTINGS":
      return {
        ...state,
        customerSettings: action.settings,
      };
    case "SET_USERS":
      return {
        ...state,
        usersList: action.users,
      };
    case "SET_USER_TO_EDIT":
      return {
        ...state,
        userToEdit: action.user,
      };
    case "SET_ORDERS_LIST":
      return {
        ...state,
        ordersList: action.orders,
      };
    case "SET_ORDERS_TRANSACTION":
      return {
        ...state,
        orderEventTransactions: action.transactions,
      };
    case "SET_GROUPS_LIST":
      return {
        ...state,
        groupsList: action.groupsList,
      };
    case "SET_GROUP_TO_EDIT":
      return {
        ...state,
        groupToEdit: action.groupToEdit,
      };
    case "UPDATE_CUSTOMER":
      let clonedList = [...state.customerList];
      clonedList.forEach((list: any) => {
        if (list.id == action.id) {
          delete list.rentPerDay;
          delete list.rentFreeDays;
          delete list.maxRentPeriod;
          delete list.totalAssetCost;
        }
      });
      return {
        ...state,
        customerList: clonedList,
      };
    case "CONFIRM_REPORT_EMPTY":
      return {
        ...state,
        reportConfirmed: action.reportConfirmed,
      };
    case "SET_CLIENT_LOCATIONS":
      return {
        ...state,
        clientDeviceLocations: action.clientDeviceLocations,
      };
    case "SET_ORDER_FILTER_ITEMS":
      return {
        ...state,
        userFilterItems: action.userFilterItems,
      };
    default:
      return {
        ...state,
      };
  }
};

export default dataReducer;
