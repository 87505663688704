import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, Stack } from "@mui/material";
import { Download } from "@mui/icons-material";
// import { saveAs } from "file-saver";
export default function ImageViewerDialog(props: { imageViewerDialogState: any; setImageViewerDialogState: Function }) {
  const { imageViewerDialogState, setImageViewerDialogState } = props;
  const handleClose = () => {
    setImageViewerDialogState((prev) => ({ open: false, row: null }));
  };
  const downloadImg = (url: string) => {
    // saveAs(url, "img.png");
    window.open(
      `${url}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  return (
    <div>
      <Dialog fullScreen open={imageViewerDialogState.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent sx={{ width: "100%", height: "40rem" }}>
          <Box sx={{ display: "flex", width: "100%", flexDirection: "row", gap: 2, justifyItems: "space-between" }}>
            {imageViewerDialogState?.row?.fotos?.map((f: any) => (
              <Box sx={{ display: "flex", width: "50%", flexDirection: "column", justifyContent: "flex-start" }}>
                <Button onClick={() => downloadImg(f.file_url)} sx={{ width: "8rem" }} variant="outlined" startIcon={<Download />}>
                  Download
                </Button>
                <img style={{ objectFit: "cover", width: "100%" }} src={f.file_url} alt="file" />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ borderRadius: "4px", p: 1, width: "10rem" }} onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
