import firebase from "../config/fbConfig";
import {
  setCalculatedLocations,
  setClientDeviceLocations,
  setCustomerList,
  setOrdersEventsTransactions,
  setOrdersList,
  setTransactionsList,
  setUserFilterItems,
  updateDeviceData,
} from "../store/actions/dataActions";
import store from "../store/store";
import _ from "underscore";
import moment from "moment";
import { showErrorToast, showSuccessToast } from "../utils/Toast";
import { strings } from "../utils/localStrings";
import { getStatusDescription } from "./DataService";

export const startListeningToDevices = async (
  gateways: any,
  currentUser: any
) => {
  // const snap = await firebase.database().ref('/customers/' + store.getState().auth.loggedInCompany.companyId + '/tag_list').once('value');
  // let tempData = [] as any
  // snap.forEach(function (child) {
  //     tempData.push({
  //         device: child.key,
  //         id: child.key,
  //         ...child.val()
  //     })
  // })
  // startDeviceListener(tempData, gateways);
  await getDeviceData(gateways, currentUser);
  startListeners(gateways);
  // setInterval(async function () {
  //     // your code goes here...
  //     await getDeviceData(tempData, gateways)
  // }, 60 * 1000);
  // startDeviceListener(tempData, gateways)
};

let deviceDataArr: any[] = [];
let transactionsArr: any[] = [];

export const getDeviceData = async (gatewaylist: any, currentUser: any) => {
  const customerId = store.getState().auth.loggedInCompany.companyId;
  let ref: any;
  if (currentUser.smarti_client) {
    ref = firebase
      .database()
      .ref(`customers/${customerId}/tag_list`)
      .orderByValue()
      .equalTo(currentUser.clientId);
  } else {
    ref = firebase.database().ref("/customers/" + customerId + "/tags");
  }
  let devices = [] as any;
  let transactions = [] as any;
  let orderEventsTransaction = [] as any;
  const start = Date.now();
  const data = await ref.once("value");
  // console.log(data.exists(), currentUser.clientId, "tags");

  if (data.exists()) {
    // console.log(data.numChildren(), "tags");
    const dataVal = data.val();
    const not_seen_after_minutes = store.getState().data.customerSettings.not_seen_after_minutes ?? 15;

    if (currentUser.smarti_client) {
      await Promise.all(
        Object.keys(dataVal).map(async (key) => {
          const promises = [];

          promises.push(
            firebase
              .database()
              .ref(`/customers/${customerId}/tags/${key}/info`)
              .once("value")
          );
          promises.push(
            firebase
              .database()
              .ref(`/customers/${customerId}/tags/${key}/order`)
              .once("value")
          );
          promises.push(
            firebase
              .database()
              .ref(`/customers/${customerId}/tag_events/tags/${key}/gateway_change_events`)
              .orderByChild("timestamp")
              .limitToLast(5)
              .once("value")
          );
          promises.push(
            firebase
              .database()
              .ref(`/customers/${customerId}/tag_events/tags/${key}/order_events`)
              .orderByChild("timestamp")
              .limitToLast(5)
              .once("value")
          );
          promises.push(
            firebase
              .database()
              .ref(`/customers/${customerId}/tag_events/tags/${key}/tag_events`)
              .orderByChild("uts")
              .limitToLast(2)
              .once("value")
          );
          promises.push(
            firebase
              .database()
              .ref(`/customers/${customerId}/tag_events/tags/${key}/order_events`)
              .once("value")
          );
          const result = await Promise.all(promises);

          const tagInfo = result[0];
          const orderData = result[1];
          const tagTransactions = result[2];
          const orderTransactions = result[3];
          const tagStatuses = result[4];
          const orderEvents = result[5];

          if (orderEvents.exists()) {
            // orderEventsList.push(orderEvents.val());
            let orderEventTtransaction = getOrderEventsTransaction(
              orderEvents.val(),
              key,
              tagInfo.val()?.serial
            );

            orderEventsTransaction.push(...orderEventTtransaction);
          }
          if (orderTransactions.exists()) {
            let tempTransactions = getOrderTransactions(
              key,
              orderTransactions.val(),
              tagInfo.val()?.serial
            );
            transactions.push(...tempTransactions);
          }
          if (tagStatuses.exists()) {
            let tempTransactions = getStatusTransactions(
              key,
              tagStatuses.val(),
              tagInfo.val()?.serial
            );
            transactions.push(...tempTransactions);
          }
          let lastTransaction;
          let lastTransactionTime;
          let lastLocation;
          if (tagTransactions.exists()) {
            let tempTransactions = getTransactions(
              key,
              tagTransactions.val(),
              tagInfo.val()?.serial,
              gatewaylist
            );
            transactions.push(...tempTransactions);
            tempTransactions.sort(function compare(a: any, b: any) {
              var da = new Date(a.timestamp).getTime();
              var db = new Date(b.timestamp).getTime();
              return da > db ? -1 : da < db ? 1 : 0;
            });
            lastTransaction =
              (tempTransactions[0].last_closest_gateway !== "none"
                ? getLocation(
                  tempTransactions[0].last_closest_gateway,
                  gatewaylist
                )
                : "NONE") +
              " => " +
              (tempTransactions[0].new_closest_gateway !== "none"
                ? getLocation(
                  tempTransactions[0].new_closest_gateway,
                  gatewaylist
                )
                : "NONE");
            lastTransactionTime = moment(
              new Date(tempTransactions[0].timestamp)
            ).format("DD-MM-YY HH:mm:ss");
            lastLocation = getLocation(
              tempTransactions[0].new_closest_gateway,
              gatewaylist
            );
          } else {
            lastTransaction = "NONE";
          }
          devices.push({
            id: key,
            serial: tagInfo.val()?.serial,
            device: key,
            location: orderData.val()?.status ?? "scanned",
            assetType: tagInfo.val()?.model,
            empty: orderData.val()?.empty,
            empty_uts: orderData.val()?.empty_uts,
            assetStatus: tagInfo.val()?.status ?? null,
            temperature: tagInfo.val()?.temp ? tagInfo.val()?.temp + " ℃" : null,
            locatie: lastLocation,
            currentLocation:
              getCurrentLocation(tagInfo.val(), lastLocation, orderData.val(), not_seen_after_minutes),
            item: tagInfo.val()?.type,
            ref: orderData.val()?.orderId,
            orderId: orderData.val()?.orderId,
            commissienaam: orderData.val()?.customerReference,
            klant: orderData.val()?.address?.name,
            klantId: orderData.val()?.customerNumber,
            lastSeen: tagInfo.val()?.last_seen_uts
              ? new Date(tagInfo.val().last_seen_uts)
              : "", //moment(new Date(tempObj?.info?.last_seen_uts)).format("DD-MM-YY HH:mm:ss") : '',
            laatsteTransactie: lastTransaction,
            laatsteTransactieTijd: lastTransactionTime ?? "",
            transactions: tagTransactions.val(),
            timeUntillNow: calculateTimeUntillNow(
              tagInfo.val()?.last_seen_uts,
              true
            ),
            leverdatum: orderData.val()?.deliveryDate, //new Date(orderData.val()?.deliveryDate).toLocaleDateString('nl-NL'),
            billingExpiryDate: tagInfo.val()?.billing_exp_date,
          });
        })
      );
    } else {
      await Promise.all(
        Object.keys(dataVal).map(async (key) => {
          const promises = [];
          const tagInfo = dataVal[key].info;
          const orderData = dataVal[key].order;
          // const tagTransactions = result[2];
          // const orderTransactions = result[3];
          // const tagStatuses = result[4];
          // const orderEvents = result[5];

          let lastTransaction = 'NONE';
          let lastTransactionTime;
          let lastLocation;
          if (dataVal[key].timestamp_since_closest)
            lastTransactionTime = moment(
              new Date(dataVal[key].timestamp_since_closest)
            ).format("DD-MM-YY HH:mm:ss");
          if (dataVal[key].closest_gateway)
            lastLocation = getLocation(
              dataVal[key].closest_gateway,
              gatewaylist
            );
          if (dataVal[key].closest_gateway) {
            lastTransaction =
              (dataVal[key].last_closest_gateway !== "none"
                ? getLocation(
                  dataVal[key].last_closest_gateway,
                  gatewaylist
                )
                : "NONE") +
              " => " +
              (dataVal[key].closest_gateway !== "none"
                ? getLocation(
                  dataVal[key].closest_gateway,
                  gatewaylist
                )
                : "NONE");
            let tempTransactions = getTransactions(
              key,
              {
                lastTrans: {
                  last_closest_gateway: dataVal[key].last_closest_gateway ?? null,
                  new_closest_gateway: dataVal[key].closest_gateway,
                  timestamp: dataVal[key].timestamp_since_closest
                }
              },
              tagInfo?.serial,
              gatewaylist
            );
            transactions.push(...tempTransactions);
          }
          devices.push({
            id: key,
            serial: tagInfo?.serial,
            device: key,
            location: orderData?.status ?? "scanned",
            assetType: tagInfo?.model,
            empty: orderData?.empty,
            empty_uts: orderData?.empty_uts,
            assetStatus: tagInfo?.status ?? null,
            temperature: tagInfo?.temp ? tagInfo?.temp + " ℃" : null,
            locatie: lastLocation,
            disabled: tagInfo?.disabled ?? false,
            currentLocation:
              getCurrentLocation(tagInfo, lastLocation, orderData, not_seen_after_minutes),
            item: tagInfo?.type,
            ref: orderData?.orderId,
            orderId: orderData?.orderId,
            commissienaam: orderData?.customerReference,
            klant: orderData?.address?.name,
            klantId: orderData?.customerNumber,
            lastSeen: tagInfo?.last_seen_uts
              ? new Date(tagInfo.last_seen_uts)
              : "", //moment(new Date(tempObj?.info?.last_seen_uts)).format("DD-MM-YY HH:mm:ss") : '',
            laatsteTransactie: lastTransaction,
            laatsteTransactieTijd: lastTransactionTime ?? "",
            transactions: [],
            timeUntillNow: calculateTimeUntillNow(
              tagInfo?.last_seen_uts,
              true
            ),
            leverdatum: orderData?.deliveryDate, //new Date(orderData.val()?.deliveryDate).toLocaleDateString('nl-NL'),
            billingExpiryDate: tagInfo?.billing_exp_date,
          });
        })
      );
    }

    // console.log(devices)
    // console.log(transactions)
    deviceDataArr = devices;
    transactionsArr = transactions;
    updateDeviceData(devices);
  }
  setTransactionsList(transactions);
  // get clientDeviceLocations if client
  // fetch orders with tags per order
  if (currentUser.smarti_client) {
    await fetchOrders(currentUser, devices, orderEventsTransaction);
    getClientDeviceLocations(devices);
  }
  setOrdersEventsTransactions(orderEventsTransaction);
  const end = Date.now();
  console.log('It took', Number((end - start) / 1000).toFixed(2), 'seconds to load tag data');
  return Promise.resolve();
};


const getCurrentLocation = (tagInfo, lastLocation, order, not_seen_after_minutes) => {
  if (!tagInfo) return strings.notSeen;
  let lastSeen = tagInfo.last_seen_uts
  if (!lastSeen && tagInfo.lastSeen) {
    lastSeen = tagInfo.lastSeen.toISOString();
  }
  if (
    lastSeen &&
    calculateTimeUntillNow(lastSeen, false) <= not_seen_after_minutes
  ) {
    return lastLocation;
  } else if (order && order.empty) {
    return strings.reportedEmpty;
  } else {
    return strings.notSeen;
  }
};
const startListeners = async (gatewaylist: any) => {
  await Promise.all(
    deviceDataArr.map(async (device: any) => {
      // listener aanmaken
      try {
        let initialCounter = 1;
        firebase
          .database()
          .ref(
            `/customers/${store.getState().auth.loggedInCompany.companyId
            }/tags/${device.device}/info`
          )
          .on(
            "value",
            async (snapshot) => {
              if (initialCounter === 1) {
                // initial value
              } else {
                const localDevice: any = deviceDataArr.find(
                  (x: any) => x.id === device.device
                );
                if (localDevice) {
                  const latestData = snapshot.val();
                  if (latestData.last_seen_uts && !(localDevice.lastSeen instanceof Date)) {

                  }
                  localDevice.lastSeen = latestData.last_seen_uts
                    ? new Date(latestData.last_seen_uts)
                    : "";
                  localDevice.timeUntillNow = calculateTimeUntillNow(
                    latestData?.last_seen_uts,
                    true
                  );
                  localDevice.disabled = latestData.disabled ?? false;
                  localDevice.item = latestData.type;
                  localDevice.assetStatus = latestData.status ?? null;
                }
              }
              initialCounter++;
            },
            (errorObject) => {
              // console.log("The read failed: " + errorObject);
            }
          );
        let initialOrder = true;
        firebase
          .database()
          .ref(
            `/customers/${store.getState().auth.loggedInCompany.companyId
            }/tags/${device.device}/order`
          )
          .on(
            "value",
            async (snapshot) => {
              if (initialOrder) {
                // initial value
                initialOrder = false;
              } else {
                const localDevice: any = deviceDataArr.find(
                  (x: any) => x.id === device.device
                );
                if (localDevice) {
                  if (snapshot.exists()) {
                    // console.log('order update received', localDevice.device, localDevice.ref, snapshot.val().orderId);
                    localDevice.ref = snapshot.val().orderId;
                    localDevice.commissienaam =
                      snapshot.val()?.customerReference;
                    localDevice.klant = snapshot.val()?.address?.name;
                    localDevice.klantId = snapshot.val()?.customerNumber;
                    localDevice.leverdatum = new Date(
                      snapshot.val()?.deliveryDate
                    ).toLocaleDateString("nl-NL");
                  } else {
                    // console.log('order update received. Order removed!', localDevice.device, localDevice.ref, localDevice.leverdatum);
                    localDevice.ref = null;
                    localDevice.commissienaam = null;
                    localDevice.klant = null;
                    localDevice.klantId = null;
                    localDevice.leverdatum = null;
                  }
                }
              }
              // updateDeviceData(() => finalArr)
            },
            (errorObject) => {
              console.log("Order listener failed: ", errorObject);
            }
          );
        let initialEvent = 1;
        firebase
          .database()
          .ref(
            `/customers/${store.getState().auth.loggedInCompany.companyId
            }/tag_events/tags/${device.device}/gateway_change_events`
          )
          .orderByChild("timestamp")
          .limitToLast(1)
          .on(
            "child_added",
            async (snapshot) => {
              if (!snapshot.exists()) {
                console.log('snapshot does not exist');
              }
              if (initialEvent === 1) {
                // initial value
              } else {
                const localDevice: any = deviceDataArr.find(
                  (x: any) => x.id === device.device
                );
                if (localDevice) {
                  localDevice.laatsteTransactie =
                    (snapshot.val().last_closest_gateway !== "none"
                      ? getLocation(
                        snapshot.val().last_closest_gateway,
                        gatewaylist
                      )
                      : "NONE") +
                    " => " +
                    (snapshot.val().new_closest_gateway !== "none"
                      ? getLocation(
                        snapshot.val().new_closest_gateway,
                        gatewaylist
                      )
                      : "NONE");
                  localDevice.laatsteTransactieTijd = moment(
                    new Date(snapshot.val().timestamp)
                  ).format("DD-MM-YY HH:mm:ss");
                  localDevice.locatie = getLocation(
                    snapshot.val().new_closest_gateway,
                    gatewaylist
                  );
                  localDevice.currentLocation = localDevice.locatie;
                  // console.log('new transaction:', localDevice.id, localDevice.laatsteTransactie, localDevice.laatsteTransactieTijd, localDevice.locatie);
                  const transaction = {
                    deviceId: localDevice.device,
                    deviceSerial: localDevice.serial,
                    id: snapshot.key,
                    ...snapshot.val(),
                    van:
                      getLocation(
                        snapshot.val().last_closest_gateway,
                        gatewaylist
                      ) !== undefined
                        ? getLocation(
                          snapshot.val().last_closest_gateway,
                          gatewaylist
                        )
                        : "NONE",
                    naar: getLocation(
                      snapshot.val().new_closest_gateway,
                      gatewaylist
                    ),
                    timestamp: new Date(snapshot.val().timestamp),
                  };
                  // console.log('new transaction', transaction);
                  try {
                    transactionsArr.push(transaction);
                  } catch (err) {
                    transactionsArr = [].concat(transactionsArr);
                    transactionsArr.push(transaction);
                  }
                }
              }
              initialEvent++;
            },
            (errorObject) => {
              console.log("gw events listener failed: ", errorObject);
            }
          );
        let initialOrderEvent = true;
        firebase
          .database()
          .ref(
            `/customers/${store.getState().auth.loggedInCompany.companyId
            }/tag_events/tags/${device.device}/order_events`
          )
          .orderByChild("timestamp")
          .limitToLast(1)
          .on(
            "child_added",
            async (snapshot) => {
              // if (initialOrderEvent) {
              //   initialOrderEvent = false;
              // } else {
              const localDevice: any = deviceDataArr.find(
                (x: any) => x.id === device.device
              );
              if (localDevice) {
                const transaction = formatOrderTransaction(
                  localDevice.id,
                  localDevice.serial,
                  snapshot.key,
                  snapshot.val()
                );
                if (snapshot.val().timestamp) {
                  try {
                    // console.log('new order event', localDevice.id, transaction);
                    transactionsArr.push(transaction);
                  } catch (err) {
                    transactionsArr = [].concat(transactionsArr);
                    transactionsArr.push(transaction);
                  }
                }
              }
              // }
            },
            (errorObject) => {
              // console.log("The read failed: " + errorObject);
            }
          );
        let initialStatusEvent = true;
        firebase
          .database()
          .ref(
            `/customers/${store.getState().auth.loggedInCompany.companyId
            }/tag_events/tags/${device.device}/tag_events`
          )
          .orderByChild("uts")
          .limitToLast(1)
          .on(
            "child_added",
            async (snapshot) => {
              // if (initialStatusEvent) {
              //   initialStatusEvent = false;
              // } else {
              const localDevice: any = deviceDataArr.find(
                (x: any) => x.id === device.device
              );
              if (localDevice) {
                const transaction = formatTagEvent(
                  localDevice.id,
                  localDevice.serial,
                  snapshot.key,
                  snapshot.val()
                );
                try {
                  // console.log('new order event', localDevice.id, transaction);
                  transactionsArr.push(transaction);
                } catch (err) {
                  transactionsArr = [].concat(transactionsArr);
                  transactionsArr.push(transaction);
                }
              }
              // }
            },
            (errorObject) => {
              // console.log("The read failed: " + errorObject);
            }
          );
      } catch (error) {
        console.log("Error: ", error);
      }
    })
  ).then(() => {
    setInterval(async function () {
      // console.log('updating from local arrays');
      updateTimeUntilNow();
      deviceDataArr = [].concat(deviceDataArr);
      transactionsArr = [].concat(transactionsArr);
      // console.log(transactionsArr);
      updateDeviceData(deviceDataArr);
      setTransactionsList(transactionsArr);
      calculateItemsPerLocation(deviceDataArr);
      // calculateItemsPerCustomer(deviceDataArr); // TODO: improve loading times -- currently freezes front-end
      store.getState().auth.loggedInUser.smarti_client &&
        getClientDeviceLocations(deviceDataArr);
    }, 30 * 1000);
    // updateDeviceData(finalArr)
    // setTransactionsList(finalTransactionsArr)
  });
};

const getLocation = (id: any, gatewaylist: any) => {
  let locations = gatewaylist;
  let location = locations.filter((location: any) => location.id === id);
  return location[0]?.locationName !== undefined
    ? location[0]?.locationName
    : strings.notSeen;
};

function updateTimeUntilNow() {
  const not_seen_after_minutes = store.getState().data.customerSettings.not_seen_after_minutes ?? 15;
  deviceDataArr.map((device) => {
    if (device.lastSeen) {
      device.timeUntillNow = calculateTimeUntillNow(
        device.lastSeen.toISOString(),
        true
      );
      device.currentLocation = getCurrentLocation(
        device,
        device.locatie,
        device,
        not_seen_after_minutes
      );
    }
  });
}
// getClientDeviceLocations
export const getClientDeviceLocations = (devices: any) => {
  if (devices.length) {
    let locations = [] as any;
    // filter empty reported devices and add to as a 'ready for pick up' location
    let emptyReportedDevices = devices?.filter((device: any) => device.empty);
    locations.push({
      id: "Ready for pick-up",
      location: "Ready for pick-up",
      totalAssets: emptyReportedDevices?.length,
      assets: emptyReportedDevices,
    });

    let filteredDevices = devices?.filter((device: any) => !device.empty);
    let groupedDevices = _.groupBy(
      filteredDevices,
      (device: any) => device.location
    );
    Object.entries(groupedDevices).map(([key, value]: any) => {
      let locationName;
      if (key == "undefined") {
        locationName = getStatusDescription("scanned");
      } else {
        locationName = getStatusDescription(key);
      }
      locations.push({
        id: locationName,
        location: locationName,
        totalAssets: value?.length,
        assets: value,
      });
    });

    setClientDeviceLocations(locations);
    // setLocationsRow(locations);
  }
};
//!transaction -> periode fn
export async function getTransactionsFromPeriod(from: string, to: string) {
  const customerId = store.getState().auth.loggedInCompany.companyId;
  const locations = store.getState().data.locationList;
  let arr: any[] = [];
  // console.log('retreiving transactions..');
  let existingFound = false;
  await Promise.all(
    deviceDataArr.map(async (device) => {
      const transactions = await firebase
        .database()
        .ref(`/customers/${customerId}/tag_events/tags/${device.id}/gateway_change_events`)
        .orderByChild("timestamp")
        .startAt(from)
        .endAt(to)
        .once("value");
      const orderTransactions = await firebase
        .database()
        .ref(`/customers/${customerId}/tag_events/tags/${device.id}/order_events`)
        .orderByChild("timestamp")
        .startAt(from)
        .endAt(to)
        .once("value");

      if (transactions.exists()) {
        Object.entries(transactions.val()).map(([key, val]: any) => {
          const exists = transactionsArr.find((x) => x.id === key);
          if (exists) {
            existingFound = true;
            return;
          }
          arr.push({
            deviceId: device.id,
            deviceSerial: device.serial,
            id: key,
            ...val,
            van:
              getLocation(val?.last_closest_gateway, locations) !== undefined
                ? getLocation(val.last_closest_gateway, locations)
                : "NONE",
            naar: getLocation(val.new_closest_gateway, locations),
            timestamp: new Date(val.timestamp),
          });
        });
      }
      if (orderTransactions.exists()) {
        Object.entries(orderTransactions.val()).map(([key, val]: any) => {
          let data = formatOrderTransaction(device.id, device.serial, key, val);
          if (val.timestamp) arr.push(data);
        });
      }
    })
  );
  transactionsArr = transactionsArr.concat(arr);
  setTransactionsList(transactionsArr);
  if (arr.length === 0) {
    if (existingFound)
      showSuccessToast(
        `Geen transacties opgehaald. Alle transacties van de geselecteerde periode zijn al zichtbaar.`,
        "bottom-center"
      );
    else {
      showErrorToast(
        "Er zijn geen transacties gevonden voor de selecteerde periode.",
        "bottom-center"
      );
    }
  } else {
    showSuccessToast(`${arr.length} transacties opgehaald`, "bottom-center");
  }
}

const getTransactions = (
  deviceId: string,
  transactions: any,
  serial: string,
  gatewaylist: any
) => {
  let tempArr = [] as any;
  Object.entries(transactions).map(([key, val]: any) => {
    tempArr.push({
      deviceId: deviceId,
      deviceSerial: serial,
      id: deviceId,
      ...val,
      van:
        getLocation(val?.last_closest_gateway, gatewaylist) !== undefined
          ? getLocation(val.last_closest_gateway, gatewaylist)
          : "NONE",
      naar: getLocation(val.new_closest_gateway, gatewaylist),
      timestamp: new Date(val.timestamp),
    });
  });
  return tempArr;
};

const getOrderTransactions = (
  deviceId: string,
  transactions: any,
  serial: string
) => {
  let tempArr: any[] = [];
  Object.entries(transactions).map(([key, val]: any) => {
    let data = formatOrderTransaction(deviceId, serial, key, val);
    if (val.timestamp) tempArr.push(data);
  });
  return tempArr;
};

const getStatusTransactions = (
  deviceId: string,
  transactions: any,
  serial: string
) => {
  let tempArr: any[] = [];
  Object.entries(transactions).map(([key, val]: any) => {
    let data = formatTagEvent(deviceId, serial, key, val);
    if (val.uts) tempArr.push(data);
  });
  return tempArr;
};

const formatOrderTransaction = (
  deviceId: string,
  serial: string,
  key: string,
  transaction: any
) => {
  let from, to;
  switch (transaction.event) {
    case "added":
      from = "NO ORDER";
      to = transaction.order_id;
      break;
    case "removed":
      to = "NO ORDER";
      from = transaction.order_id;
      break;
    default:
      break;
  }
  return {
    deviceId: deviceId,
    deviceSerial: serial,
    id: key,
    ...transaction,
    van: from,
    naar: to,
    description: getDescription(transaction),
    timestamp: new Date(transaction.timestamp),
  };
};

const getOrderEventsTransaction = (orderEvent: any, key: any, tagInfo: any) => {
  let tempOrderEventTransaction = [] as any;
  Object.entries(orderEvent).map(([key, val]: any) => {
    tempOrderEventTransaction.push({ ...val, key, tagInfo });
  });
  return tempOrderEventTransaction;
};
const formatTagEvent = (
  deviceId: string,
  serial: string,
  key: string,
  transaction: any
) => {
  return {
    deviceId: deviceId,
    deviceSerial: serial,
    id: key,
    ...transaction,
    van: transaction.lastStatus,
    naar: transaction.newStatus,
    description: transaction.image,
    timestamp: new Date(transaction.uts),
  };
};

const getDescription = (transaction: any) => {
  switch (transaction.description.toLowerCase()) {
    case "returned":
      const time = SplitTime(transaction.duration);
      return (
        strings.returned_description +
        " " +
        time.days +
        strings.days +
        " " +
        time.hours +
        strings.hours
      );
    case "returned_swijnenburg":
      return strings.returned_sw_description;
    case "ivenza":
      return "Ivenza scan";
    default:
      return transaction.description;
  }
};

export const calculateItemsPerCustomer = async (data: any) => {
  let filteredDevices = data.filter(
    (device: any) => device.klant !== undefined
  );
  let grouped = _.groupBy(filteredDevices, (device) => device.klant);
  let devices = [] as any;
  let customerList = store.getState().data.customerList;
  const lost_at_customer_days =
    store.getState().data.customerSettings.lost_at_customer_days ?? 21;
  Object.keys(grouped).map(function (key) {
    devices.push({
      name: key,
      devices: grouped[key],
    });
    for (let i = 0; i < customerList.length; i++) {
      let tempData = devices.filter(
        (device: any) => device.name === customerList[i].name
      );

      if (tempData.length > 0) {
        customerList[i].devices = tempData[0].devices;
        customerList[i].kistenVerloren = calculateAmountOfDays(
          tempData[0].devices,
          lost_at_customer_days
        );
        customerList[i].kistenXDagen = calculateAmountOfDays(
          tempData[0].devices,
          100
        );
        customerList[i].aantalKisten = tempData[0].devices.length;
      } else {
        customerList[i].aantalKisten = 0;
        customerList[i].kistenVerloren = 0;
        customerList[i].kistenXDagen = 0;
      }

      customerList[i].tat = customerList[i].tat ?? "--";

      customerList[i].totalTat = customerList[i].totalTat ?? 0;
    }
  });
  setCustomerList([].concat(customerList));
};
// fetch orders
export const fetchOrders = async (
  currentUser: any,
  devices: any,
  orderEventsTranaction = [] as any,
  startDate = "" as any,
  endDate = "" as any,
  ordersFromRange = false as any
) => {
  if (ordersFromRange) {
    return await getOrdersFromRange(
      currentUser,
      devices,
      orderEventsTranaction,
      startDate,
      endDate
    );
  }
  if (currentUser.smarti_client) {
    return await firebase
      .firestore()
      .collection("customers")
      .doc(store.getState().auth.loggedInCompany.companyId)
      .collection("orders")
      .where("customerNumber", "==", currentUser.clientId)
      .limit(1000)
      .get()
      .then((querySnapshot: any) => {
        let ordersList = querySnapshot.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data(),
        }));
        return modifyOrder(devices, orderEventsTranaction, ordersList);
      }); //currentUser.customerNumber
    // let ordersList = ordesRef.docs.map((doc) => (doc.id, doc.data()));
    // console.log("order list", ordersList.length);
  } else {
    return await firebase
      .firestore()
      .collection("customers")
      .doc(store.getState().auth.loggedInCompany.companyId)
      .collection("orders")
      .orderBy("deliveryDate", "desc")
      .limit(1000)
      .get()
      .then((querySnapshot: any) => {
        let ordersList = querySnapshot.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data(),
        }));

        return modifyOrder(devices, orderEventsTranaction, ordersList);
      });
  }
};

// getOrdersFromRange
const getOrdersFromRange = async (
  currentUser: any,
  devices: any,
  orderEventsTranaction: any,
  startDate: any,
  endDate: any
) => {
  if (currentUser.type === "client") {
    return await firebase
      .firestore()
      .collection("customers")
      .doc(store.getState().auth.loggedInCompany.companyId)
      .collection("orders")
      .orderBy("scanTimestamp")
      .startAt(startDate)
      .endAt(endDate)
      .where("customerNumber", "==", currentUser.clientId)
      .get()
      .then((querySnapshot: any) => {
        let ordersList = querySnapshot.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data(),
        }));

        return modifyOrder(devices, orderEventsTranaction, ordersList);
      });
  } else {
    return await firebase
      .firestore()
      .collection("customers")
      .doc(store.getState().auth.loggedInCompany.companyId)
      .collection("orders")
      .orderBy("scanTimestamp")
      .startAt(startDate)
      .endAt(endDate)
      .get()
      .then((querySnapshot: any) => {
        let ordersList = querySnapshot.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data(),
        }));
        return modifyOrder(devices, orderEventsTranaction, ordersList);
      });
  }
};
const modifyOrder = (
  devices: any,
  orderEventsTranaction: any,
  ordersList: any
) => {
  let tweakOrders = ordersList?.map((order: any) => {
    if (order.scanTimestamp) {
      return {
        ...order,
        daysAtCustomer: calculateDaysAtCustomer(order.deliveryDate),
        scanTimeStampDate:
          typeof order.scanTimestamp === "string"
            ? new Date(order.scanTimestamp).toLocaleDateString("nl-NL")
            : order.scanTimestamp.toDate().toLocaleDateString("nl-NL"),
      };
    }
  });

  // get devices
  // filter devices  !undefined
  let filteredDevices = devices.filter(
    (device: any) => device.orderId !== undefined
  );
  // group devices with same orderId
  let groupedDevices = _.groupBy(filteredDevices, (device) => device.orderId);

  // filter transations  !undefined
  let filteredTransactions = orderEventsTranaction.filter(
    (tra: any) => tra.order_id !== undefined
  );

  // group transaction by orderId
  let groupedTransactions = _.groupBy(
    filteredTransactions,
    (transaction) => transaction.order_id
  );

  //add tags and orderEvent transactions for each orders
  let ordersListEnTagsEnTransactions = tweakOrders.map((order: any) => {
    let orderId = order.orderId;
    // console.log(orderId, groupedDevices[orderId]);
    return {
      ...order,
      orderId,
      tags: groupedDevices[orderId] ? groupedDevices[orderId] : [],
      orderTransactions: groupedTransactions[orderId]
        ? groupedTransactions[orderId]
        : [],
    };
  });

  // set orders to redux
  setOrdersList(ordersListEnTagsEnTransactions);
  return ordersListEnTagsEnTransactions;
};

const calculateDaysAtCustomer = (deliveryDate: string) => {
  let dateDelivery = new Date(deliveryDate) as any;
  let now = new Date() as any;
  let diffTime = Math.abs(now - dateDelivery);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const calculateItemsPerLocation = async (data: any) => {
  let filteredDevices = data.filter(
    (device: any) => device.currentLocation !== undefined && !device.disabled
  );
  let grouped = _.groupBy(filteredDevices, (device) => device.currentLocation);
  let devices = [] as any;
  Object.keys(grouped).map(function (key) {
    devices.push({
      name: key,
      devices: grouped[key],
    });
    return devices;
  });
  let locationsList = store.getState().data.locations;
  for (let i = 0; i < locationsList.length; i++) {
    let tempData = devices.filter(
      (device: any) => device.name === locationsList[i].locationName
    );
    if (tempData.length > 0) {
      const locationDevices = tempData[0].devices.filter(x => !x.assetStatus || x.assetStatus === 'operational');
      locationsList[i].devices = locationDevices;
      locationsList[i].aantalKisten = locationDevices.length;
      locationsList[i].assetsDamagedOrBroken = calculateAssetStateTotal(
        tempData[0],
        ["broken", "damaged"]
      );
      // console.log('location', locationsList[i]);
    } else {
      locationsList[i].devices = [];
      locationsList[i].aantalKisten = 0;
      locationsList[i].assetsDamagedOrBroken = 0;
    }
    // console.log('set locations', locationsList);
  }
  setCalculatedLocations([].concat(locationsList));
};
//cualculatetotalAssetCost per customer
// export const cualculatetotalAssetCost = () => {
//   let customersList = store.getState().data.customerList;

//   for (let i = 0; i < customersList.length; i++) {
//     if (customersList[i].devices?.length) {
//       customersList[i].totalAssetCost = getAssetCostsPerCustomer(
//         customersList[i],
//         customersList[i].devices,
//         customersList[i].rentPerDay,
//         customersList[i].rentFreeDays
//       );
//     } else {
//       customersList[i].totalAssetCost = 0;
//     }
//   }
// };

// whenever changes happens on client
export const getAssetCostsPerCustomer = (
  client: any,
  devices: any,
  rentPerDay: any,
  rentFreeDays: any
) => {
  // filter empty reported devices
  let reportedDevices = devices.filter((device: any) => device.empty);

  // cualculate assetcosts
  let totalAssetCost = 0 as any;
  reportedDevices.forEach((tag: any) => {
    let deliveryDate = Date.parse(tag.leverdatum);
    let emptyReportedDate = tag.empty_uts;
    // let rentFreeDays = client.rentFreeDays;
    // let rentPerDay = client.rentPerDay;
    if (emptyReportedDate > deliveryDate) {
      let diffDays = Math.ceil(
        (emptyReportedDate - deliveryDate) / (1000 * 60 * 60 * 24)
      );
      totalAssetCost += (diffDays - rentFreeDays) * rentPerDay;
    }
  });
  // // !when changes on client niveau
  if (client.groupRef || client.groupId) {
    // delete ref in firestore
    firebase
      .firestore()
      .collection("customers")
      .doc(store.getState().auth.loggedInCompany.companyId)
      .collection("clients")
      .doc(client.customerNumber)
      .update({
        groupRef: firebase.firestore.FieldValue.delete(),
      });
    store.getState().data.customerList.forEach((customer: any) => {
      if (customer.customerNumber == client.customerNumber) {
        delete customer.groupRef;
        delete customer.groupId;
        delete customer.groupName;
      }
    });
  }

  return totalAssetCost;
};
const calculateAmountOfDays = (devices: any, days: any, belowDays?: any) => {
  // Over alle devices loopen en kijken hoeveel er langer dan X days  weg zijn
  const dayInMillisecond = 86400000;
  let positiveArr = [] as any;
  for (let i = 0; i < devices.length; i++) {
    if (devices[i].lastSeen !== "") {
      let daysDiff = Math.floor(
        (new Date().getTime() - new Date(devices[i].lastSeen).getTime()) /
        dayInMillisecond
      );
      if (daysDiff > days) {
        positiveArr.push(daysDiff);
      }
    }
  }

  return positiveArr.length;
};

const calculateTimeUntillNow = (timestamp: any, stringify: boolean) => {
  let diff = "" as any;

  if (timestamp !== undefined) {
    diff = Math.floor(
      (new Date().getTime() - new Date(timestamp).getTime()) / 60000
    );

    if (stringify) {
      diff = converToHoursAndMinutes(diff);
    }
  }
  return diff;
};

function SplitTime(numberOfHours: number) {
  var Days = Math.floor(numberOfHours / 24);
  var Remainder = numberOfHours % 24;
  var Hours = Math.floor(Remainder);
  var Minutes = Math.floor(60 * (Remainder - Hours));
  return { days: Days, hours: Hours, min: Minutes };
}

const converToHoursAndMinutes = (minutes: any) => {
  let d = Math.floor(minutes / 1440);
  let h = Math.floor((minutes - d * 1440) / 60);
  let m = minutes - d * 1440 - h * 60;
  let hhmm =
    (d > 0 ? d + "d " : "") +
    h.toString() +
    "u " +
    (m < 10 ? "0" : "") +
    m.toString() +
    "m";
  return hhmm;
};

const calculateAssetStateTotal = (tempData: any, status: string[]) => {
  let total = [] as any;
  if (!tempData) return 0;
  tempData.devices.forEach((device: any) => {
    if (device.assetStatus) {
      const found = status.find(x => device.assetStatus.includes(x));
      if (found)
        total.push(device);
    }
  });

  return total.length;
};

// report empty service of one order
export const reportEmpty = async (
  orderDocId: any,
  selectionModel: any,
  ordersList: any,
  reportSingleDevice = false as boolean
) => {
  let orderRef = firebase
    .firestore()
    .collection("customers")
    .doc(store.getState().auth.loggedInCompany.companyId)
    .collection("orders")
    .doc(`${orderDocId}`);

  if (reportSingleDevice) {
    const tagId = selectionModel;
    await orderRef.set(
      {
        empty_times: firebase.firestore.FieldValue.arrayUnion({
          id: tagId,
          uts: new Date(),
        }),
      },
      { merge: true }
    );
    // update device status
    let ref = firebase
      .database()
      .ref(
        "customers/" +
        store.getState().auth.loggedInCompany.companyId +
        "/tags/" +
        tagId +
        "/order"
      );

    if (ref) {
      await ref.update({
        empty: true,
        empty_uts: firebase.database.ServerValue.TIMESTAMP,
        status: "empty",
      });
    }
  } else {
    for (let i = 0; i < selectionModel.length; i++) {
      const tagId = selectionModel[i];

      await orderRef.set(
        {
          empty_times: firebase.firestore.FieldValue.arrayUnion({
            id: tagId,
            uts: new Date(),
          }),
        },
        { merge: true }
      );
      // update device status
      console.log(tagId);

      let ref = firebase
        .database()
        .ref(
          "customers/" +
          store.getState().auth.loggedInCompany.companyId +
          "/tags/" +
          tagId +
          "/order"
        );

      if (ref) {
        console.log(ref);

        await ref.update({
          empty: true,
          empty_uts: firebase.database.ServerValue.TIMESTAMP,
          status: "empty",
        });
      }
    }
  }

  // update redux
  let ref = await orderRef.get();
  let emptiedArr = ref.data()?.empty_times ?? [];
  let updatedOrdersList = ordersList.map((order: any) =>
    order.id === orderDocId
      ? {
        ...order,
        empty_times: [...emptiedArr],
      }
      : order
  );

  setOrdersList(updatedOrdersList);
};

export const handleFilterModelChange = (
  model,
  details,
  table: string,
  userFilterItems,
  userId
) => {
  // remove undefined for firestore and add table
  let filterItems = model.items.map((item) => {
    if (item.value == undefined) {
      return {
        ...item,
        value: "",
        table,
      };
    } else {
      return {
        ...item,
        table,
      };
    }
  });
  // update  redux
  let fiterdEl = userFilterItems.filter((el) => el.table !== table);
  let tempUserFilterItems = [...fiterdEl, ...filterItems];
  setUserFilterItems(tempUserFilterItems);
  // add to firestore
  firebase
    .firestore()
    .collection(`users/${userId}/smarti/settings/filterItems/`)
    .doc("/items/")
    .set({
      tempUserFilterItems,
    })
    .catch((e) => {
      console.log(e);
    });
};
