import React from "react";
import "./App.css";
import { Switch, Route, HashRouter as Router } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import StyledGrid from "./components/Grid/StyledGrid";
import MenuDrawer from "./components/Menu/Menu";
import { useEffect } from "react";
import styled from "styled-components";
import CustomTopbar from "./components/Topbar/Topbar";
import FilterDrawer from "./components/FilterTemp/Filter";
import { connect } from "react-redux";
import { openMenu } from "./store/actions/layoutActions";
import * as S from "./components/styles";
import "@mui/lab/themeAugmentation";
import DateAdapter from "@mui/lab/AdapterMoment";
import { strings } from "./utils/localStrings";
import Notfound from "./components/NotFound/Notfound";

import {
  calculateItemsPerCustomer,
  calculateItemsPerLocation,
} from "./services/DatabaseService";
import ClientDashboard from "./components/Client/ClientDashboard";
import Orders from "./components/Orders/Orders";
import { Settings } from "@mui/icons-material";
import ClientSettings from "./components/Client/ClientSettings";
import Customers from "./components/Customers/Customers";
import Kpi from "./components/Kpi/Kpi";
import UserManager from "./components/UserManagment/UserManager";
import Workflows from "./components/Workflows/Workflows";
import DataView from "./components/DataView/DataView";
import TableImport from "./components/TableImport/TableImport";

function mapStateToProps(state: any) {
  return {
    layout: state.layout,
    loggedInUser: state.auth.loggedInUser,
    currentCompany: state.auth.loggedInCompany,
    settings: state.data.customerSettings,
    devices: state.data.devices,
    userRoutes: state.auth.userRoutes,
  };
}

function App(props: any) {
  const { loggedInUser } = props;
  const { userRoutes } = props;

  const [contentWidth, setContentWidth] = React.useState("100px");
  const [marginLeft, setMarginLeft] = React.useState("0px");
  useEffect(() => {
    calculateItemsPerCustomer(props.devices); // Sets setCalculatedLocations
    calculateItemsPerLocation(props.devices); // Sets setCustomerList
  }, []);
  useEffect(() => {
    const calculateMainContentSize = () => {
      if (props.layout.menuOpen && props.layout.filterOpen) {
        setContentWidth(
          `calc(100% - ${props.layout.menuWidth}px - ${props.layout.filterWidth}px)`
        );
        setMarginLeft(`${props.layout.menuWidth}px`);
      } else if (props.layout.menuOpen && !props.layout.filterOpen) {
        setContentWidth(`calc(100% - ${props.layout.menuWidth}px)`);
        setMarginLeft(`${props.layout.menuWidth}px`);
      } else if (!props.layout.menuOpen && props.layout.filterOpen) {
        setContentWidth(`calc(100% - ${props.layout.filterWidth}px)`);
        setMarginLeft(`0px`);
      } else {
        setContentWidth("100%");
        setMarginLeft(`0px`);
      }
    };
    return calculateMainContentSize();
  }, [
    props.layout.menuOpen,
    props.layout.menuWidth,
    props.layout.filterOpen,
    props.layout.filterWidth,
  ]);
  useEffect(() => {
    //set language preferences
    strings.setLanguage(
      props.settings.preffered_language && props.settings.preffered_language
    );
  }, [props.settings.preffered_language]);
  const handleMenuStatus = () => {
    openMenu(!props.layout.menuOpen);
  };

  return (
    // <LocalizationProvider dateAdapter={DateAdapter}>
    <Router>
      <StyledGrid container height="100%">
        <CustomTopbar
          id="appbar"
          width={contentWidth}
          marginLeft={marginLeft}
          height={S.TOOLBAR.height}
          handleOpenSidebar={handleMenuStatus}
        />
        <MenuDrawer
          sidebarWidth={props.layout.menuWidth}
          sidebarOpen={props.layout.menuOpen}
          handleCloseDrawer={handleMenuStatus}
        />
        <StyledMain width={contentWidth} marginLeft={marginLeft}>
          <FilterDrawer />
          <Switch>
            <Route exact path="/" component={TableImport} />
            <Route path="/Dashboard" component={Dashboard} />
            {userRoutes?.map((route: any, i: any) => (
              <Route key={i} path={route.path} component={route.component} />
            ))}
            <Route component={Notfound} />
            <Route exact path="/" render={() => <TableImport />} />
            <Route path="/dashboard" render={() => <Dashboard />} />
            <Route
              path="/dashboard/client"
              render={() => <ClientDashboard />}
            />
            <Route path="/Orders" render={() => <Orders />} />
            {!loggedInUser.smarti_client && !loggedInUser.smarti_viewer && (
              <Route path="/users" render={() => <UserManager />} />
            )}
            {!loggedInUser.smarti_viewer && (
              <Route path="/settings" render={() => <Settings />} />
            )}
            {!loggedInUser.smarti_client && (
              <>
                <Route path="/datasheet" render={() => <DataView />} />
                <Route path="/customers" render={() => <Customers />} />
                <Route path="/workflow" render={() => <Workflows />} />
                {/* <Route path="/KPI" render={() => <Kpi />} /> */}
              </>
            )}

            {loggedInUser.smarti_client && (
              <Route
                path="/client/settings"
                render={() => <ClientSettings />}
              />
            )}

            <Route component={Notfound} />
          </Switch>
        </StyledMain>
      </StyledGrid>
    </Router>
    // </LocalizationProvider>
  );
}

const StyledMain = styled.main<{ width?: any; marginLeft?: any }>`
  width: ${(props) => props.width} !important;
  max-width: ${(props) => props.width} !important;
  margin-left: ${(props) => props.marginLeft} !important;
  height: ${`calc(100% - ${S.TOOLBAR.height}px)`};
  max-height: ${`calc(100% - ${S.TOOLBAR.height}px)`} !important;
  overflow: auto;
  flex-grow: 1;
  padding: 0px !important;
  background-color: #f0f0f0 !important;
`;

export default connect(mapStateToProps)(App);
