import {
    Button,
    TableContainer,
} from "@mui/material";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { handleFilterModelChange } from "../../services/DatabaseService";
import { GridCallbackDetails, GridColDef, GridFilterModel, DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { BtnContainer } from "../Orders/Styles";
import store from "../../store/store";
import firebase from "firebase";
import { showSuccessToast } from "../../utils/Toast";

function mapStateToProps(state: any) {
    return {
        currentUser: state.auth.loggedInUser,
        ordersModalOpen: state.layout.ordersModalOpen,
        orderEventTransactions: state.data.orderEventTransactions,
        userFilterItems: state.data.userFilterItems,
        ordersTransactionModalOpen: state.layout.ordersTransactionModalOpen,
    };
}
const TableImport = ({
    ordersModalOpen,
    currentUser,
    ordersTransactionModalOpen,
    userFilterItems,
}: any) => {
    // grid cols
    const cols: GridColDef[] = [
        {
            field: "article",
            headerName: "Item",
            // flex: 1,
            editable: true
        },
        {
            field: "A",
            headerName: "Repack A",
            // flex: 1,
            editable: true
        },
        {
            field: "B",
            headerName: "Repack B",
            // flex: 1,
            editable: true
        },
        {
            field: "C",
            headerName: "Repack C",
            // flex: 1,
            editable: true
        },
        {
            field: "D",
            headerName: "Repack D",
            // flex: 1,
            editable: true
        },
        {
            field: "A-UM",
            headerName: "UM A",
            // flex: 1,
            editable: true
        },
        {
            field: "B-UM",
            headerName: "UM B",
            // flex: 1,
            editable: true
        },
        {
            field: "C-UM",
            headerName: "UM C",
            // flex: 1,
            editable: true
        },
        {
            field: "A-ICPL",
            headerName: "ICPL A",
            // flex: 1,
            editable: true
        },
        {
            field: "B-ICPL",
            headerName: "ICPL B",
            // flex: 1,
            editable: true
        },
        {
            field: "C-ICPL",
            headerName: "ICPL C",
            // flex: 1,
            editable: true
        },
        {
            field: "A-ICPL+OCL",
            headerName: "ICPL+OCL A",
            // flex: 1,
            editable: true
        },
        {
            field: "B-ICPL+OCL",
            headerName: "ICPL+OCL B",
            // flex: 1,
            editable: true
        },
        {
            field: "C-ICPL+OCL",
            headerName: "ICPL+OCL C",
            // flex: 1,
            editable: true
        },
        {
            field: "A-ICPL+UM",
            headerName: "ICPL+UM A",
            // flex: 1,
            editable: true
        },
        {
            field: "B-ICPL+UM",
            headerName: "ICPL+UM B",
            // flex: 1,
            editable: true
        },
        {
            field: "C-ICPL+UM",
            headerName: "ICPL+UM C",
            // flex: 1,
            editable: true
        },
        {
            field: "A-ICHL",
            headerName: "ICHL A",
            // flex: 1,
            editable: true
        },
        {
            field: "B-ICHL",
            headerName: "ICHL B",
            // flex: 1,
            editable: true
        },
        {
            field: "C-ICHL",
            headerName: "ICHL C",
            // flex: 1,
            editable: true
        },
        {
            field: "A-ICNL",
            headerName: "ICNL A",
            // flex: 1,
            editable: true
        },
        {
            field: "B-ICNL",
            headerName: "ICNL B",
            // flex: 1,
            editable: true
        },
        {
            field: "C-ICNL",
            headerName: "ICNL C",
            // flex: 1,
            editable: true
        },
        {
            field: "A-OTHER",
            headerName: "OTHER A",
            // flex: 1,
            editable: true
        },
        {
            field: "B-OTHER",
            headerName: "OTHER B",
            // flex: 1,
            editable: true
        },
        {
            field: "C-OTHER",
            headerName: "OTHER C",
            // flex: 1,
            editable: true
        },
        {
            field: "ICL",
            headerName: "ICL",
            // flex: 1,
            editable: true
        },
        {
            field: "OCL",
            headerName: "OCL",
            // flex: 1,
            editable: true
        },
        {
            field: "ICL+OCL",
            headerName: "ICL+OCL",
            // flex: 1,
            editable: true
        },
        {
            field: "2xICL",
            headerName: "2xICL",
            // flex: 1,
            editable: true
        },
        {
            field: "ICL+UM",
            headerName: "ICL+UM",
            // flex: 1,
            editable: true
        },
        {
            field: "ICL+OCL+UM",
            headerName: "ICL+OCL+UM",
            // flex: 1,
            editable: true
        },
        {
            field: "UM",
            headerName: "UM",
            // flex: 1,
            editable: true
        },
        {
            field: "Repack+label IC",
            headerName: "Repack + label IC",
            // flex: 1,
            editable: true
        },
        {
            field: "Delabel ICL",
            headerName: "Delabel ICL",
            // flex: 1,
            editable: true
        },
        {
            field: "Delabel OCL",
            headerName: "Delabel OCL",
            // flex: 1,
            editable: true
        },
        {
            field: "Delabel ICL+OCL",
            headerName: "Delabel ICL+OCL",
            // flex: 1,
            editable: true
        },
        {
            field: "Delabel 2xICL",
            headerName: "Delabel 2xICL",
            // flex: 1,
            editable: true
        },
        {
            field: "Delabel ICL+UM",
            headerName: "Delabel ICL+UM",
            // flex: 1,
            editable: true
        },
        {
            field: "Delabel ICL+OCL+UM",
            headerName: "Delabel ICL+OCL+UM",
            // flex: 1,
            editable: true
        },
        {
            field: "Remove UM",
            headerName: "Remove UM",
            // flex: 1,
            editable: true
        },
        {
            field: "VAS Other",
            headerName: "VAS Other",
            // flex: 1,
            editable: true
        },
        {
            field: "VAS Other 2",
            headerName: "VAS Other 2",
            // flex: 1,
            editable: true
        },
    ];
    // state
    const [value, setValue] = useState(0);
    const [rows, setRows] = useState([]); // { article: 'article', '2xICL': '', A: '' }
    const [loading, setLoading] = useState(false); // { article: 'article', '2xICL': '', A: '' }
    let event = false;

    const arr = [];


    const handleCellEdit = async (row: any) => {
        let currentItem = rows.find(
            (i: any) => i.article === row.id
        );
        // console.log(currentItem, row.id, rows);
        if (currentItem) {
            currentItem[row.field] = row.value;
            // console.log('updating row', row.field, row.value);
        }
    };

    const handleKeyDown = (event) => {
        // console.log('key', event.keyCode);
        if (event.keyCode === 13) {
            console.log('Enter key pressed')
        }
    }

    const save = async () => {
        const customer = store.getState().auth.loggedInCompany.companyId;
        setLoading(true);
        await Promise.all(rows.map(async row => {
            const ref = firebase
                .firestore().doc(`/customers/${customer}/static_data_tables/VxiNyNcIdcMT4lvQqzLm/${row.article}/data`);
            await ref.set(row, { merge: true });
        }));

        setLoading(false);
        showSuccessToast(
            rows.length + " row(s) saved",
            "bottom-center"
        );
    }

    useEffect(() => {
        if (!event) {
            event = true;
            window.addEventListener('paste', e => {
                // console.log('event paste', e);
                // showSuccessToast("loading copied data..",
                //     "bottom-center"
                // );
                navigator.clipboard.readText()
                    .then(text => {
                        // console.log('Pasted content: ', text);
                        text.split('\r\n').map(row => {
                            // console.log('row', row.split('\t'));
                            const obj = {};
                            row.split('\t').map((cell, index) => {
                                const fieldname = cols[index]?.field;
                                // console.log(index, cell, fieldname);
                                if (fieldname) {
                                    obj[fieldname] = cell;
                                } else {
                                    // console.log('no fieldname found', cell);
                                }
                            })
                            arr.push(obj);

                        })
                        // console.log(arr);
                        setRows(arr);
                    })
                    .catch(err => {
                        console.error('Failed to read clipboard contents: ', err);
                    });
            });
        }
    })

    return (
        <div onKeyDown={handleKeyDown}><Container>
            {/* {ordersModalOpen && <OrderInfoModal currentTab={value} />}
            {ordersTransactionModalOpen && <OrdersTransaction />}

            <StyledTabs
                value={value}
                onChange={(e: any, val: any) => changeTabValue(val)}
                centered
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Open orders" />
                <Tab label="Completed orders" />
            </StyledTabs> */}
            {/* search and export */}

            <StyledDataGrid
                getRowId={(row) => row.article}
                // checkboxSelection
                // disableSelectionOnClick
                rows={rows}
                columns={cols}
                rowHeight={30}
                pageSize={100}
                components={{ Toolbar: GridToolbar }}
                onCellEditCommit={(row: any) => handleCellEdit(row)}
            // onCellClick={(params) => openTransactionModal(params)}
            // onRowDoubleClick={(row: any) => showAssetsList(row)}
            // onFilterModelChange={(
            //     model: GridFilterModel,
            //     details: GridCallbackDetails
            // ) => filterModelChanged(model, details, "openOrders")}
            // initialState={{
            //     filter: {
            //         filterModel: {
            //             items: userFilterItems?.filter(
            //                 (items) => items.table === "openOrders"
            //             ),
            //         },
            //     },
            //     // pinnedColumns: {
            //     //   left: [
            //     //     GRID_CHECKBOX_SELECTION_COL_DEF.field,
            //     //     "scanTimeStampDate",
            //     //     "orderId",
            //     //   ],
            //     //   right: ["orderStatus"],
            //     // },
            // }}
            />

            {/* filter drawer btn  */}
        </Container>
            <BtnContainer>
                <Button
                    onClick={save}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                >
                    save
                </Button>
            </BtnContainer></div>
    );
};

export default connect(mapStateToProps)(TableImport);
const Container = styled.div`
  width: 100%;
  height: 80vh;
`;

const StyledDataGrid = styled(DataGridPro)`
  height: 100% !important;
`;
// function componentDidMount() {
//     window.addEventListener('keypress', e => {
//         console.log('event', e.key)
//     });
// }



