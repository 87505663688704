import store from "../store";

export const subscribeToData = () => {
  store.dispatch({ type: "SUBSCRIBE_TO_DATA" });
};

export const updateChips = (chips: any) => {
  store.dispatch({ type: "UPDATE_CHIPS", chips });
};
export const addChip = (chip: any) => {
  store.dispatch({ type: "ADD_CHIP", chip });
};
export const deleteChip = (id: any) => {
  store.dispatch({ type: "DELETE_CHIP", id });
};
export const updateSelectedContent = (selectedContent: any) => {
  store.dispatch({ type: "UPDATE_SELECTED_CONTENT", selectedContent });
};

export const updateDeviceData = (deviceData: any) => {
  store.dispatch({ type: "UPDATE_DEVICE_DATA", deviceData });
};

export const updateSingleDevice = (deviceData: any, index: any) => {
  store.dispatch({ type: "UPDATE_SINGLE_DEVICE", deviceData, index });
};

export const updateTransactiesFilter = (filterString: any) => {
  store.dispatch({ type: "UPDATE_TRANSACTIES_FILTER", filterString });
};

export const setUpdater = (update: boolean) => {
  store.dispatch({ type: "UPDATE_UPDATER", update });
};

export const setCustomerList = (customers: any) => {
  store.dispatch({ type: "UPDATE_CUSTOMERLIST", customers });
};

export const setLocationList = (locations: any) => {
  store.dispatch({ type: "UPDATE_LOCATIONS", locations });
};

export const setCalculatedLocations = (locationsData: any) => {
  store.dispatch({ type: "UPDATE_LOCATIONSLIST", locationsData });
};

export const setTransactionsList = (transactions: any) => {
  // console.log("Dispatched UPDATE_TRANSACTIONSLIST")
  store.dispatch({ type: "UPDATE_TRANSACTIONSLIST", transactions });
};

export const setCustomerSettings = (settings: any) => {
  // console.log("Dispatched UPDATE_TRANSACTIONSLIST")
  store.dispatch({ type: "UPDATE_CUSTOMER_SETTINGS", settings });
};
export const setUsersList = (users: any) => {
  store.dispatch({ type: "SET_USERS", users });
};
export const setUserToEdit = (user: any) => {
  store.dispatch({ type: "SET_USER_TO_EDIT", user });
};
export const setOrdersList = (orders: any) => {
  store.dispatch({ type: "SET_ORDERS_LIST", orders });
};
export const setOrdersEventsTransactions = (transactions: any) => {
  store.dispatch({ type: "SET_ORDERS_TRANSACTION", transactions });
};
export const setGroupsList = (groupsList: any) => {
  store.dispatch({ type: "SET_GROUPS_LIST", groupsList });
};
export const setGroupToEdit = (groupToEdit: any) => {
  store.dispatch({ type: "SET_GROUP_TO_EDIT", groupToEdit });
};
export const updateCustomer = (id: any) => {
  store.dispatch({ type: "UPDATE_CUSTOMER", id });
};
export const updateCustomerFromGroup = (groupInfo: any, id: any) => {
  store.dispatch({ type: "UPDATE_CUSTOMER_FROM_GROUP", groupInfo, id });
};
export const setEmptyReportConfirmed = (reportConfirmed: boolean) => {
  store.dispatch({ type: "CONFIRM_REPORT_EMPTY", reportConfirmed });
};
export const setClientDeviceLocations = (clientDeviceLocations: any) => {
  store.dispatch({ type: "SET_CLIENT_LOCATIONS", clientDeviceLocations });
};
export const setUserFilterItems = (userFilterItems: any) => {
  store.dispatch({ type: "SET_ORDER_FILTER_ITEMS", userFilterItems });
};
