/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react"; //jsx is required here for the UI rendering.
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, IconButton, Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridSelectionModel,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import {
  setBuilderEditingMode,
  setSelectedWorkflowIndex,
  setWorkflowPage,
} from "../../../../store/actions/workflowActions";
import { getAndSetWorkflowActions } from "../../../../services/WorkflowService";
import { IWorkflow } from "../../../../models/workflows/Workflow";
import {
  DeleteOutline,
  EditOutlined,
  ForwardOutlined,
  PublishOutlined,
} from "@mui/icons-material";
import { Status } from "../../WorkFlowProcess/styles";
import DetailPanelContent from "../DetailPanelContent";
import { handleFilterModelChange } from "../../../../services/DatabaseService";

function mapStateToProps(state: any) {
  return {
    showHiddenWorkflows: state.workflow.showHiddenWorkflows,
    userFilterItems: state.data.userFilterItems,
    currentUser: state.auth.loggedInUser,
  };
}

const pageSize = 100;

const WorkflowTable = (props: {
  currentUser: any;
  workflows: any;
  showHiddenWorkflows: boolean;
  loading: boolean;
  publishWorkflow: (wf: IWorkflow) => void;
  deleteWorkflow: (wf: IWorkflow) => void;
  userFilterItems: any;
  selectionModel: GridSelectionModel;
  setSelectionModel: (model: GridSelectionModel) => void;
}) => {
  const [filteredWorkflows, setFilteredWorkflows] = React.useState(
    props.workflows
  );

  useEffect(() => {
    // if (props.workflows.length <= 0) return;
    if (props.showHiddenWorkflows) setFilteredWorkflows(props.workflows);
    else
      setFilteredWorkflows(
        props.workflows.filter((w: IWorkflow) => w.template == true)
      );
  }, [props.workflows, props.showHiddenWorkflows]);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Workflow title",
      width: 240,
      sortable: true,
    },
    {
      field: "orderNumber",
      headerName: "Order ID",
      width: 240,
      sortable: false,
      hide: true,
    },
    {
      field: "availableAt",
      headerName: "Available at",
      renderCell: (p) => {
        const wf: IWorkflow = p.row as IWorkflow;
        const date = new Date(wf.activatedAt).toLocaleString();

        return <p>{date}</p>;
      },
      width: 240,
      sortable: true,
    },
    {
      field: "activatedAt",
      headerName: "Activated at",
      renderCell: (p) => {
        const wf: IWorkflow = p.row as IWorkflow;
        const date = new Date(wf.activatedAt).toLocaleString();

        return <p>{date}</p>;
      },
      width: 240,
      sortable: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      renderCell: (p) => {
        const wf: IWorkflow = p.row as IWorkflow;
        const date = new Date(wf.activatedAt).toLocaleString();

        return <p>{date}</p>;
      },
      width: 240,
      sortable: true,
    },
    {
      field: "activated_by_name",
      headerName: "Updated by",
      width: 180,
      sortable: true,
    },
    {
      field: "§§§",
      headerName: "Published",
      width: 130,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (p) => {
        const wf: IWorkflow = p.row as IWorkflow;
        if (wf.availableAt == null) return <div>No</div>;
        return (
          <div>
            {new Date() > wf.availableAt
              ? "Yes"
              : `Set for ${wf.availableAt.toLocaleDateString()}`}
          </div>
        );
      },
    },
    {
      field: "statusCode",
      headerName: "Status",
      width: 200,
      renderCell: (p) => {
        const wf: IWorkflow = p.row as IWorkflow;
        return <Status status={wf.statusCode}>{wf.statusCode}</Status>;
      },
    },
    {
      field: "controls",
      headerName: "Controls",
      sortable: false,
      width: 140,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (p) => {
        const workflowIsActivated = p.row.activatedAt == null;
        if (workflowIsActivated == false) {
          return (
            <div>
              {/* <IconButton aria-label="forward"
              onClick={() => alert("todo")}>
              <ForwardIcon />
            </IconButton> */}
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={async () => {
                  props.deleteWorkflow(p.row as IWorkflow);
                }}
              >
                <DeleteOutline />
              </IconButton>
            </div>
          );
        } else {
          return (
            <div>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={async () => {
                    setBuilderEditingMode(true)
                    await getAndSetWorkflowActions(p.row as IWorkflow);
                    setSelectedWorkflowIndex([-1, -1]);
                    setWorkflowPage(1);
                
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={async () => {
                    props.deleteWorkflow(p.row as IWorkflow);
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
              <Tooltip title="Publish/Unpublish">
                <IconButton
                  aria-label="publish"
                  onClick={async () => {
                    props.publishWorkflow(p.row as IWorkflow);
                  }}
                >
                  <PublishOutlined />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];

  // functions
  const filterModelChanged = (model, details, table) => {
    handleFilterModelChange(
      model,
      details,
      table,
      props.userFilterItems,
      props.currentUser.id
    );
  };
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 600, []);
  return (
    <Paper
      css={css`
        height: 100%;
      `}
    >
      <DataGridPro
        components={{ Toolbar: GridToolbar }}
        checkboxSelection
        loading={props.loading}
        onSelectionModelChange={(newSelectionModel) => {
          props.setSelectionModel(newSelectionModel);
        }}
        selectionModel={props.selectionModel}
        disableSelectionOnClick
        rows={filteredWorkflows}
        columns={columns}
        pageSize={pageSize}
        css={css`
          background-color: white;
        `}
        onFilterModelChange={(
          model: GridFilterModel,
          details: GridCallbackDetails
        ) => filterModelChanged(model, details, "workflow")}
        initialState={{
          filter: {
            filterModel: {
              items: props.userFilterItems?.filter(
                (items) => items.table === "workflow"
              ),
            },
          },
        }}
        pinnedColumns={{
          left: [
            GRID_DETAIL_PANEL_TOGGLE_FIELD,
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
          ],
          right: ["statusCode", "controls"],
        }}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
    </Paper>
  );
};

export default connect(mapStateToProps)(WorkflowTable);
