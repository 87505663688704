import moment from "moment";
import React from "react";
import firebase from "../config/fbConfig";

interface TableProps { }
export const useQualityCheckData = (selectedDate: string | undefined) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<TableProps[]>([]);
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (selectedDate == undefined) return;
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [selectedDate]);

  const getData = async () => {
    let formatedDate = moment(selectedDate).format("DD/MM/YYYY");
    try {
      setLoading(true);
      const snapData = await firebase.firestore().collection("customers").doc("RAmCqOcw49bHdHZZsC5d").collection("productivity_forms").where("dateString", "==", formatedDate).get();
      //   if (snapData.docs.length <= 0) return setLoading(false);

      let docs = snapData.docs.map((d) => d);
      let rows: any[] = [];
      for await (const doc of docs) {
        let formsSnap = await doc.ref.collection("forms").where("title", "==", "Quality check").get();
        // if (formsSnap.docs.length <= 0) return setLoading(false);

        const row = formsSnap.docs.map((d) => ({
          id: d.id,
          date: d.data()?.date?.toDate(),
          pro_co: doc.data()?.user_name,
          article: d.data()?.form_data?.article,
          fotos: d.data()?.form_data?.pictures,
          program: d.data()?.form_data?.program,
          pro_location: d.data().form_data["production location"],
          label_ic: d.data()?.form_data?.qualitycheck[0][1],
          label_oc: d.data()?.form_data?.qualitycheck[1][1],
          um: d.data()?.form_data?.qualitycheck[2][1],
          polybag: d.data()?.form_data?.qualitycheck[3][1],
          protector: d.data()?.form_data?.qualitycheck[4][1],
          silk_paper: d.data()?.form_data?.qualitycheck[5][1],
          closing_label: d.data()?.form_data?.qualitycheck[6][1],
          ean_code: d.data()?.form_data?.qualitycheck[7][1],
          delabel_ic: d.data()?.form_data?.qualitycheck[8] ? d.data()?.form_data?.qualitycheck[8][1] : null,
          delabel_oc: d.data()?.form_data?.qualitycheck[9] ? d.data()?.form_data?.qualitycheck[9][1] : null,
          icp_sleeve: d.data()?.form_data?.qualitycheck[10] ? d.data()?.form_data?.qualitycheck[10][1] : null,
          ocp_ocb: d.data()?.form_data?.qualitycheck[11] ? d.data()?.form_data?.qualitycheck[11][1] : null,
          tag: d.data()?.form_data?.qualitycheck[12] ? d.data()?.form_data?.qualitycheck[12][1] : null,
          timeslot: d.data()?.form_data?.timeslot,
        }));

        rows.push(row);
      }
      setData(rows.flat());
      setLoading(false);

      return rows;
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  return {
    error,
    loading,
    data,
  };
};
